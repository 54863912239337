<template>
  <div v-if="EditItem">
    <el-dialog title="实习生情况" :visible.sync="dialogshow" :close-on-click-modal="false" :modal-append-to-body="false"
      :destroy-on-close="true" @close="closeDialog" width="800px" custom-class="cus_dialog">
      <div>
        <el-form :model="EditItem" ref="EditItem" label-width="120px" v-if="EditItem">
          <el-row style="padding-top: 10px;">
            <el-col :span="10">
              <el-form-item label="学生姓名" prop="stu_name" :rules="[{required: true, message:'请输入学生姓名',trigger:'blur'}]">
                <el-select v-model="EditItem.stu_name" :disabled="dialogxx" filterable remote reserve-keyword
                  placeholder="请输入姓名" :remote-method="remoteMethod" :loading="loading" style="width:180px"
                  @change="checkedxz" @focus="focusxz">
                  <el-option v-for="item in options" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="学号">
                <el-input v-model="EditItem.stu_code" disabled style="width:180px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="padding-top: 10px;">
            <el-col :span="10">
              <el-form-item label="班主任" prop="head_teacher" :rules="[{required: true, message:'请选择班主任',trigger:'change'}]">
                <el-select v-model="EditItem.head_teacher" filterable placeholder="请选择班主任" style="width:180px"
                  :disabled="dialogxx">
                  <el-option v-for="(item, idx) in bzrOptions" :key="idx" :label="item.username"
                    :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="班级名称">
                <el-input v-model="EditItem.class_name" disabled style="width:180px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="padding-top: 10px;">
            <el-col :span="22">
              <el-form-item label="内容" prop="content" :rules="[{required: true, message:'请填写内容',trigger:'blur'}]">
                <el-input v-model="EditItem.content" type="textarea" :autosize="{minRows: 4}" maxlength="2000" show-word-limit :disabled="disabled"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
<!--          <el-form-item label="附件">
            <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
              v-for="(img, idx) in EditItem.annex" :key="idx">
              <el-image style="width: 100px; height: 100px" :src="img.fileUrl || img.src"
                :preview-src-list="[img.fileUrl || img.src]" img="cover"></el-image>

              <div class="imgremove" v-if="EditItem.is_draft == 1" @click="removeImg(idx)">
                <i class="el-icon-remove"></i>
              </div>
            </div>

            <div style="display:inline-block;vertical-align: top;" v-if="EditItem.is_draft == 1">
              <el-upload style="display: inline-block; margin-right: 20px" action :http-request="uploadOss"
                :on-success="uploadFiles" :show-file-list="false" name="image">
                <div class="imgaddbtn">＋</div>
              </el-upload>
            </div>
          </el-form-item>-->

          <el-row style="padding-top: 10px;">
            <el-col :span="10">
              <el-form-item label="填报日期" prop="report_date" :rules="[{required: true, message:'请选择填报日期',trigger:'change'}]">
                <el-date-picker v-model="EditItem.report_date" :disabled="disabled" style="width:180px" align="right"
                  type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="排序号">
                <el-input-number v-model="EditItem.sort" :disabled="disabled" controls-position="right"
                  style="width:180px"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <el-row>
          <el-col :span="22" style="text-align: right;padding-left: 10px;"
            v-if="$store.state.teacher.id == EditItem.create_uid || EditItem.id == 0">
            <el-button size="small" icon="el-icon-edit" type="primary" @click="submitForm()">提交</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UEditor from "../notify/ueditor.vue";
import util from "../../utils/util.js";
export default {
  components: { UEditor },
  props: ["id"],
  data() {
    return {
      thisTeacherid: 0,
      dialogshow: true,
      dialogxx: true,
      disabled: true,
      editor: null,
      optionsRow: {
        stu_code: "",
        stu_id: "",
        stu_name: "",
        class_name: ""
      },
      EditItem: {
        id: 0,
        stu_id: 0,
        head_teacher: "",
        head_teacher_name: "",
        content: "",
        annex: [],
        report_date: "",
        class_name: "",
        sort: 99
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            }
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            }
          }
        ]
      },
      enterList: [],
      options: [],
      bzrOptionsls: [],
      bzrOptions: [],
      zdlsOptionsls: [],
      zdlsOptions: [],
      list: [],
      loading: false,
      states: []
    };
  },
  mounted() {
    this.enter_list();
    this.$$parent(this, "EditItem").then(res => {
      if (res.id == 0) {
        res.report_date = util.formatDate(new Date());
        this.disabled = false;
        this.dialogxx = false;
      } else {
        res.annex = JSON.parse(res.annex);
        this.options = [{ id: res.stu_id, label: res.stu_code }];
        this.bzrOptions = [
          { id: res.head_teacher, username: res.head_teacher_name }
        ];
        if (this.$store.state.teacher.id == res.create_uid) {
          this.disabled = false;
        } else {
          //修改不是自己的
        }
      }
      this.EditItem = res;
    });
  },
  methods: {
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        this.$http
          .post("/api/students_class_info", { stu_code: query })
          .then(res => {
            this.loading = false;
            if (res.data) {
              this.options = [
                {
                  value: res.data.id,
                  label: res.data.stu_name + "-" + res.data.stu_code
                }
              ];
              this.bzrOptionsls = res.data.bzr_ist;
              this.zdlsOptionsls = res.data.zdls_list;
              this.optionsRow.stu_code = res.data.stu_code;
              this.optionsRow.stu_id = res.data.id;
              this.optionsRow.stu_name = res.data.stu_name;
              this.optionsRow.class_name = res.data.class_name;
              this.optionsRow.enter_name = this.enterList.filter(e => e.id == res.data.enter_id).map(m => m.firm_name).pop()
            }
          });
      } else {
        this.options = [];
      }
    },
    checkedxz() {
      this.dialogxx = false;
      this.bzrOptions = this.bzrOptionsls;
      this.zdlsOptions = this.zdlsOptionsls;
      this.EditItem.head_teacher = this.bzrOptions[0].id;
      this.EditItem.stu_code = this.optionsRow.stu_code;
      this.EditItem.stu_id = this.optionsRow.stu_id;
      this.EditItem.class_name = this.optionsRow.class_name;
    },
    focusxz() {
      this.options = [];
      this.bzrOptionsls = [];
      this.zdlsOptions = [];
      this.optionsRow.head_teacher = "";
      this.optionsRow.stu_code = "";
      this.optionsRow.stu_id = "";
      this.optionsRow.class_name = "";
    },
    uploadFiles(e) {
      this.EditItem.annex.push(e);
    },
    removeImg(idx) {
      this.EditItem.annex.splice(idx, 1);
    },
    enter_list() {
      this.$http.post("/api/enterprise_list_all").then(res => {
        this.enterList = res.data;
      });
    },
    closeDialog() {
      this.$$parent(this, "dialogshow", false);
    },
    submitForm(st) {
      let EditItem = { ...this.EditItem };
      EditItem.annex = JSON.stringify(EditItem.annex);
      let data = JSON.parse(JSON.stringify(EditItem));
      let words = 5
      if (data.content.length < words) {
        this.$message.error("内容必须大于" + words + "字!");
        return false;
      }
      if (!data.stu_id) {
        this.$message.error("学生必选!");
        return false;
      }
      if (!data.report_date.trim()) {
        this.$message.error("填报时间必选!");
        return false;
      }
      if (!data.head_teacher) {
        this.$message.error("班主任必选!");
        return false;
      }
      this.$http.post("/api/stu_situation_edit", data).then(res => {
        this.$message({
          type: "success",
          message: "提交成功"
        });
        this.$$parent(this, "getList");
        this.$$parent(this, "dialogshow", false);
      });
    },
    editorReady(editorInstance) {
      editorInstance.setContent(this.EditItem.event_desc);
      editorInstance.addListener("contentChange", () => {
        this.EditItem.event_desc = editorInstance.getContent();
      });
      this.editor = editorInstance;
    }
  }
};
</script>

<style scoped>
.imgaddbtn {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dotted #ccc;
  font-size: 30px;
  cursor: pointer;
}

.imgremove {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;

  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  color: #ccc;
}

.imgremove:hover {
  color: orange;
}
</style>
