<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">
        <tchtree v-model="searchForm.class_ids"></tchtree>
        <el-input placeholder="学号或学生姓名" v-model="searchForm.stu_str" size="small" class="input-with-select"
          style="width:200px" clearable></el-input>
        <el-date-picker size="small" v-model="dateArray" type="daterange" align="right" unlink-panels range-separator="至"
          start-placeholder="填报开始日期" end-placeholder="填报结束日期" :picker-options="pickerOptions" class="input-with-select"
          style="width:400px;margin-left: 10px"></el-date-picker>

        <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
          @click="() => { page.current_page = 1; getList()}">搜索</el-button>

        <el-button icon="el-icon-plus" style="margin-left:10px" size="small" type="primary"
          @click="saveJh">新增</el-button>
      </div>

      <div class="flexStart"
        style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">
        <el-button size="mini" icon="el-icon-download" type="primary" plain style="margin-left: 10px;" @click="exportXLS">导出</el-button>
      </div>
    </el-row>
    <div class="dividerBar"></div>
     <el-table v-tableFit :data="DataList" height="100%" size="small"  stripe border style="width:fit-content;width:100%;">
      <el-table-column type="index" width="55" label="序号"></el-table-column>
      <el-table-column prop="stu_code" label="学号" width="120"></el-table-column>
      <el-table-column prop="stu_name" label="学生姓名" width="100"></el-table-column>
      <el-table-column prop="gender" label="性别" width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.gender == 1">男</span>
          <span v-if="scope.row.gender == 2">女</span>
        </template>
      </el-table-column>
      <el-table-column prop="zy_name" label="专业名称" width="120"></el-table-column>
      <el-table-column prop="class_name" label="班级名称" width="120"></el-table-column>
      <el-table-column prop="firm_name" label="实习单位" show-overflow-tooltip min-width="200"></el-table-column>
      <el-table-column prop="head_teacher_name" label="班主任" min-width="100"></el-table-column>
      <el-table-column prop="report_date" label="填报时间" min-width="100"></el-table-column>
      <el-table-column prop="create_name" label="创建人" width="120"></el-table-column>
      <el-table-column prop="created_at" label="创建时间" min-width="100"></el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-tag class="pointer" type="success" size="mini" v-if="$store.state.teacher.id == scope.row.create_uid"
            @click="viewInfo(scope.row)">编辑</el-tag>
          <el-tag class="pointer" type="primary" size="mini" v-else @click="viewInfo(scope.row)">详情</el-tag>
          <el-tag class="pointer ml10" type="danger" size="mini" @click="deleteJh(scope.row)">删除</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>
    <formdialog v-if="dialogshow" :id="EditItem.id"></formdialog>
  </div>
</template>

<script>
import formdialog from "./formdialog.vue";
import util from "../../utils/util.js";
import tchtree from "@/views/com/tchTree.vue";
export default {
  components: {
    tchtree,
    formdialog
  },
  data() {
    return {
      dateArray: [],
      thisTeacherid: 0,
      dialogshow: false,
      searchForm: {
        stu_str: "",
        class_ids: "",
        sta_date: "",
        end_date: ""
      },
      EditItem: {
        id: 0,
        stu_id: 0,
        head_teacher: 0,
        create_uid: 0,
        head_teacher_name: "",
        content: "",
        annex: [],
        report_date: "",
        class_name: "",
        sort: 99
      },
      DataList: [],
      listForm: [],
      listState: [],
      ClassList: [],
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },
  mounted() {
    this.getList();
  },
  watch: {
    dateArray(val) {
      if (val === null) {
        this.dateArray = []
      }
    }
  },
  methods: {
    getList() {
      this.$http
        .post("/api/stu_situation_list", {
          page: this.page.current_page,
          pagesize: this.page.per_page,
          keyword: this.searchForm.stu_str,
          class_ids: this.searchForm.class_ids,
          sta_date:
            this.dateArray.length > 0 ? util.formatDate(this.dateArray[0]) : "",
          end_date:
            this.dateArray.length > 0 ? util.formatDate(this.dateArray[1]) : ""
        })
        .then(res => {
          this.DataList = res.data.data;
          this.page = res.data.page;
        });
    },
    deleteJh(row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$http.post("/api/stu_situation_delete", { id: row.id }).then(res => {
            this.$message({
              type: "success",
              message: "删除成功!"
            });
          });
          this.getList();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
    addNew() {
      this.EditItem.id = 0;
      this.dialogshow = true;
    },
    saveJh() {
      this.EditItem = {
        id: 0,
        stu_id: 0,
        head_teacher: "",
        create_uid: 0,
        head_teacher_name: "",
        content: "",
        annex: [],
        report_date: "",
        class_name: "",
        sort: 99
      };
      this.dialogshow = true;
    },
    viewInfo(row) {
      this.EditItem = { ...row };
      this.EditItem.title1 = "优秀实习生事迹详情";
      this.dialogshow = true;
    },
    exportXLS() {
      this.$http.post('/api/stu_situation_export', {
        pagesize: 10000,
        keyword: this.searchForm.stu_str,
        class_ids: this.searchForm.class_ids,
        sta_date:
            this.dateArray.length > 0 ? util.formatDate(this.dateArray[0]) : "",
        end_date:
            this.dateArray.length > 0 ? util.formatDate(this.dateArray[1]) : ""
      }).then(res => {
        if (res.data.url) {
          window.open(res.data.url,'_blank')
        }
      })
    },
  }
};
</script>

<style scoped="scoped">
.spuser {
  display: inline-block;
  border: 1px solid rgb(255, 148, 62);

  padding: 2px;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  padding: 4px 10px;
}

.spuser img {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
}
</style>
